import React, { useState } from "react"
import Img from "gatsby-image"
import { Box, Flex, Text } from "rebass"
import { Input } from "@rebass/forms"
import styled from "@emotion/styled"
import useWindowDimensions from "../../../utils/use-window-dimensions"
import Spinner from "../../ui/spinner"
import Medusa from "../../../services/api"

const HeroContainer = styled(Flex)`
  width: 100%;
  position: relative;

  ${(props) => props.theme.bp.mobile} {
    height: 600px;
  }

  ${(props) => props.theme.bp.tablet} {
    height: 500px;
  }

  ${(props) => props.theme.bp.desktop} {
    height: 100vh;

    img {
      object-fit: cover !important;
    }
  }
`

const NewsletterContainer = styled(Flex)`
  position: absolute;
  bottom: 25px;
  right: 50px;
  text-decoration: none;
  color: #ffffff;
  font-family: Georgia;
  font-size: 12px;
  align-items: flex-end;
`

const StyledInput = styled(Input)`
  border-bottom: 1px solid #ffffff !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;

  width: 175px;

  &:focus {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    outline: none;
  }
`

const HeroImage = styled(Img)`
  width: 100%;
`

const SignUpButton = styled(Text)`
  cursor: pointer;
`

const Hero = ({ input }) => {
  const { image, title } = input
  const [signingUp, setSigningUp] = useState(false)
  const [signedUp, setSignedUp] = useState(false)
  const [email, setEmail] = useState("")

  const { width } = useWindowDimensions()

  let inputRef = null

  const handleNewsletterSignup = async () => {
    if (email) {
      setSigningUp(true)
      await Medusa.newsletter
        .signup({ email })
        .then(() => {
          setSignedUp(true)
          setSigningUp(false)
        })
        .catch(() => {
          setSignedUp(true)
          setSigningUp(false)
        })
    }
  }

  const HeroModule = (
    <HeroContainer>
      <HeroImage
        fluid={image.fluid}
        style={{ height: width >= 900 ? "100%" : "inherit" }}
        imgStyle={{ objectFit: width >= 900 ? "contain" : "cover" }}
      />
      <Box ml="auto" />
      {width >= 750 && (
        <NewsletterContainer flexDirection="column">
          {signingUp ? (
            <Spinner color="#F4F0E5" />
          ) : !signedUp ? (
            <>
              <Text>NEWSLETTER</Text>
              <SignUpButton onClick={() => handleNewsletterSignup()}>
                SUBSCRIBE
              </SignUpButton>{" "}
              <StyledInput
                onChange={({ currentTarget }) => setEmail(currentTarget.value)}
              />
            </>
          ) : (
            <Text>THANK YOU!</Text>
          )}
        </NewsletterContainer>
      )}
    </HeroContainer>
  )

  return HeroModule
}

export default Hero
