import React from "react"
import _ from "lodash"
import Img from "gatsby-image"
import { Flex } from "rebass"
import styled from "@emotion/styled"

const Container = styled(Flex)`
  width: 100%;
  justify-content: center;

  ${(props) => props.theme.bp.mobile} {
    padding-left: 10px;
    padding-right: 10px;
  }
`

const TileImage = styled(Img)`
  width: 100%;
  height: 100%;

  ${(props) => props.theme.bp.mobile} {
    width: 100%;
  }

  ${(props) => props.theme.bp.tablet} {
    width: 100%;
  }

  ${(props) => props.theme.bp.desktop} {
    width: 80%;
  }
`

const Tile = ({ input }) => {
  const { backgroundImage } = input

  return (
    <Container>
      <TileImage fluid={backgroundImage.fluid} />
    </Container>
  )
}

export default Tile
