import styled from "@emotion/styled"
import React, { useState } from "react"
import { Image } from "rebass"
import Cross from "../../assets/images/cross.svg"

const StyledTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  font-family: Georgia, serif !important;

  ${(props) => props.theme.bp.tablet} {
  }

  ${(props) => props.theme.bp.mobile} {
    font-size: 11px;
  }

  td:last-of-type {
    background-color: #e3e1d8;
    border-left: none;
  }

  td {
    background-color: #e3e1d8;
    border-bottom: 2px solid transparent;
    border-left: 2px solid #f2f2f2;

    ${(props) => props.theme.bp.mobile} {
      padding: 5px 5px;
    }

    ${(props) => props.theme.bp.tablet} {
      padding: 5px 10px;
      font-size: 10px;
    }

    ${(props) => props.theme.bp.desktop} {
      padding: 10px 12px;
      font-size: 12px;
    }
  }

  th {
    font-weight: normal;
    background-color: #e3e1d8;
    border-bottom: 2px solid #f2f2f2;
    border-left: 2px solid #f2f2f2;
    padding: 10px 20px;

    ${(props) => props.theme.bp.mobile} {
      padding: 5px 10px;
      font-size: 10px;
    }

    ${(props) => props.theme.bp.tablet} {
      padding: 5px 10px;
      font-size: 10px;
    }

    ${(props) => props.theme.bp.desktop} {
      padding: 8px 13px;
      font-size: 12px;
    }
  }

  th:last-of-type {
    background-color: #e3e1d8;
    border-left: none;
    padding: 0px 0px 0px 0px !important;
    width: 15px;
    text-align: center;
    position: relative;

    img {
      margin-top: 5px;
      top: 2px;
      right: 7px;
      position: absolute;
      cursor: pointer;
    }
  }
`

const Measure = styled.span`
  ${(props) =>
    props.active &&
    `
    text-decoration: underline;
    font-weight: 600;
  `}
`

const SizeGuide = ({ setShowSizeGuide, type, productTitle }) => {
  const [selectedMeasure, setSelectedMeasure] = useState("cm")

  if (type && type === "jacket" && productTitle.includes("original")) {
    return (
      <StyledTable class="tg">
        <thead>
          <tr>
            <th>
              Choose{" "}
              <Measure
                onClick={() => setSelectedMeasure("cm")}
                active={selectedMeasure === "cm"}
              >
                cm
              </Measure>{" "}
              /{" "}
              <Measure
                onClick={() => setSelectedMeasure("inch")}
                active={selectedMeasure === "inch"}
              >
                inch
              </Measure>
            </th>
            <th>Small</th>
            <th>Medium</th>
            <th>Large</th>
            <th>X-Large</th>
            <th onClick={() => setShowSizeGuide(false)}>
              <Image src={Cross} height="15px" width="15px" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Bust width</td>
            <td>{selectedMeasure === "cm" ? 102 : 40.1}</td>
            <td>{selectedMeasure === "cm" ? 108 : 42.5}</td>
            <td>{selectedMeasure === "cm" ? 112 : 44.1}</td>
            <td>{selectedMeasure === "cm" ? 118 : 46.4}</td>
            <td></td>
          </tr>
          <tr>
            <td>Waist width</td>
            <td>{selectedMeasure === "cm" ? 104 : 40.9}</td>
            <td>{selectedMeasure === "cm" ? 110 : 43.3}</td>
            <td>{selectedMeasure === "cm" ? 114 : 44.8}</td>
            <td>{selectedMeasure === "cm" ? 120 : 47.2}</td>
            <td></td>
          </tr>
          <tr>
            <td>Hip width</td>
            <td>{selectedMeasure === "cm" ? 104 : 40.9}</td>
            <td>{selectedMeasure === "cm" ? 110 : 43.3}</td>
            <td>{selectedMeasure === "cm" ? 114 : 44.8}</td>
            <td>{selectedMeasure === "cm" ? 120 : 47.2}</td>
            <td></td>
          </tr>
          <tr>
            <td>Back length</td>
            <td>{selectedMeasure === "cm" ? 66 : 25.9}</td>
            <td>{selectedMeasure === "cm" ? 68 : 26.7}</td>
            <td>{selectedMeasure === "cm" ? 70 : 27.5}</td>
            <td>{selectedMeasure === "cm" ? 70 : 27.5}</td>
            <td></td>
          </tr>
          <tr>
            <td>Shoulder width</td>
            <td>{selectedMeasure === "cm" ? 12.5 : 4.9}</td>
            <td>{selectedMeasure === "cm" ? 12.5 : 4.9}</td>
            <td>{selectedMeasure === "cm" ? 13 : 5.1}</td>
            <td>{selectedMeasure === "cm" ? 13.5 : 5.3}</td>
            <td></td>
          </tr>
          <tr>
            <td>Sleeve length</td>
            <td>{selectedMeasure === "cm" ? 57 : 22.4}</td>
            <td>{selectedMeasure === "cm" ? 60 : 23.6}</td>
            <td>{selectedMeasure === "cm" ? 63 : 24.8}</td>
            <td>{selectedMeasure === "cm" ? 63 : 24.8}</td>
            <td></td>
          </tr>
          <tr>
            <td>Wrist width</td>
            <td>{selectedMeasure === "cm" ? 28 : 11}</td>
            <td>{selectedMeasure === "cm" ? 30 : 11.8}</td>
            <td>{selectedMeasure === "cm" ? 32 : 12.5}</td>
            <td>{selectedMeasure === "cm" ? 32 : 12.5}</td>
            <td></td>
          </tr>
        </tbody>
      </StyledTable>
    )
  }

  return (
    <StyledTable class="tg">
      <thead>
        <tr>
          <th>
            Choose:{" "}
            <Measure
              onClick={() => setSelectedMeasure("cm")}
              active={selectedMeasure === "cm"}
            >
              cm
            </Measure>{" "}
            /{" "}
            <Measure
              onClick={() => setSelectedMeasure("inch")}
              active={selectedMeasure === "inch"}
            >
              inch
            </Measure>
          </th>
          <th>X-Small</th>
          <th>Small</th>
          <th>Medium</th>
          <th>Large</th>
          <th>X-Large</th>
          <th onClick={() => setShowSizeGuide(false)}>
            <Image src={Cross} height="15px" width="15px" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Bust width</td>
          <td>{selectedMeasure === "cm" ? 95 : 37.4}</td>
          <td>{selectedMeasure === "cm" ? 98 : 38.5}</td>
          <td>{selectedMeasure === "cm" ? 101 : 39.7}</td>
          <td>{selectedMeasure === "cm" ? 104 : 40.1}</td>
          <td>{selectedMeasure === "cm" ? 108 : 42.5}</td>
          <td></td>
        </tr>
        <tr>
          <td>Waist width</td>
          <td>{selectedMeasure === "cm" ? 96 : 37.7}</td>
          <td>{selectedMeasure === "cm" ? 99 : 38.9}</td>
          <td>{selectedMeasure === "cm" ? 102 : 40.1}</td>
          <td>{selectedMeasure === "cm" ? 105 : 41.3}</td>
          <td>{selectedMeasure === "cm" ? 107 : 42.1}</td>
          <td></td>
        </tr>
        <tr>
          <td>Hip width</td>
          <td>{selectedMeasure === "cm" ? 102 : 40.1}</td>
          <td>{selectedMeasure === "cm" ? 105 : 41.3}</td>
          <td>{selectedMeasure === "cm" ? 108 : 42.5}</td>
          <td>{selectedMeasure === "cm" ? 111 : 43.7}</td>
          <td>{selectedMeasure === "cm" ? 115 : 45.2}</td>
          <td></td>
        </tr>
        <tr>
          <td>Back length</td>
          <td>{selectedMeasure === "cm" ? 64.5 : 25.4}</td>
          <td>{selectedMeasure === "cm" ? 66 : 25.9}</td>
          <td>{selectedMeasure === "cm" ? 68 : 26.7}</td>
          <td>{selectedMeasure === "cm" ? 70 : 27.5}</td>
          <td>{selectedMeasure === "cm" ? 70 : 27.5}</td>
          <td></td>
        </tr>
        <tr>
          <td>Shoulder width</td>
          <td>{selectedMeasure === "cm" ? 11.5 : 4.5}</td>
          <td>{selectedMeasure === "cm" ? 11.5 : 4.5}</td>
          <td>{selectedMeasure === "cm" ? 12 : 4.7}</td>
          <td>{selectedMeasure === "cm" ? 12.5 : 4.9}</td>
          <td>{selectedMeasure === "cm" ? 13 : 5.1}</td>
          <td></td>
        </tr>
        <tr>
          <td>Sleeve length</td>
          <td>{selectedMeasure === "cm" ? 57 : 22.4}</td>
          <td>{selectedMeasure === "cm" ? 57 : 22.4}</td>
          <td>{selectedMeasure === "cm" ? 60 : 23.6}</td>
          <td>{selectedMeasure === "cm" ? 63 : 24.8}</td>
          <td>{selectedMeasure === "cm" ? 63 : 24.8}</td>
          <td></td>
        </tr>
        <tr>
          <td>Wrist width</td>
          <td>{selectedMeasure === "cm" ? 30 : 11.8}</td>
          <td>{selectedMeasure === "cm" ? 30 : 11.8}</td>
          <td>{selectedMeasure === "cm" ? 31 : 12.2}</td>
          <td>{selectedMeasure === "cm" ? 32 : 12.5}</td>
          <td>{selectedMeasure === "cm" ? 32 : 12.5}</td>
          <td></td>
        </tr>
      </tbody>
    </StyledTable>
  )
}

export default SizeGuide
