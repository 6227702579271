import React, { useContext, useEffect, useState } from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import _ from "lodash"
import { Flex, Text } from "rebass"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import InterfaceContext from "../../context/interface-context"
import {
  currencyCodeToSymbol,
  formatVariantPrice,
  formatVariantSalePrice,
  getProductPrice,
  getVariantPrice,
  getVariantSalePrice,
} from "../../utils/prices"
import Medusa from "../../services/api"
import TileSection from "../page/content-modules/tile-section"
import ContentModules from "../page/content-modules"
import StoreContext from "../../context/store-context"
import SizeGuide from "./size-guide"

const Container = styled(Flex)`
  width: 100%;
  padding-left: 35px;
  padding-right: 35px;

  ${(props) => props.theme.bp.tablet} {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }

  ${(props) => props.theme.bp.desktop} {
    flex-direction: row;
    padding-left: 35px;
    padding-right: 35px;
  }

  ${(props) => props.theme.bp.mobile} {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  flex-direction: column;

  margin-bottom: 200px;
`

const ProductImage = styled(Img)`
  width: 100%;
  height: 100%;

  ${(props) => props.theme.bp.tablet} {
    flex-basis: 100%;
  }

  ${(props) => props.theme.bp.desktop} {
    flex-basis: 50%;
  }
`

const Description = styled(Flex)`
  ${(props) => props.theme.bp.tablet} {
    flex-basis: 100%;
  }
  ${(props) => props.theme.bp.desktop} {
    flex-basis: 50%;
  }

  flex-basis: 100%;
`

const RichText = styled.div`
  font-family: Georgia;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22pt;
  width: 100%;

  a {
    color: #454545;
  }

  ${(props) => props.theme.bp.mobile} {
    > div {
      margin-top: 20px;
      margin-bottom: 25px;
    }
    font-size: 14px;
    line-height: 16pt;
  }

  ${(props) => props.theme.bp.tablet} {
    font-size: 14px;

    div {
      line-height: 22pt;
      margin-bottom: 20px;
    }
  }

  ${(props) => props.theme.bp.desktop} {
    font-size: 14px;
    margin-left: 8%;
    margin-right: 10%;

    div {
      line-height: 22pt;
      margin-bottom: 20px;
    }
  }
`

const AddToCart = styled(Text)`
  cursor: pointer;

  ${(props) =>
    props.isSoldOut &&
    `
    pointer-events:none;
    opacity: 0.3;
  `}

  &:hover {
    text-decoration: underline;
  }

  display: inline-block;
`

const ViewSizeGuide = styled(Text)`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  display: inline-block;
`

const StyledSelect = styled.select`
  width: 100px !important;
  margin-bottom: 30px !important;
  background-color: #f4f0e5 !important;
  outline: none !important;
  border: none !important;
  margin-left: -3px !important;
  font-size: 14px !important;
`

const SmallText = styled.p`
  font-size: 12px;
`

const renderOptions = {
  renderNode: {
    [BLOCKS.HEADING_6]: (node, children) => <SmallText>{children}</SmallText>,
  },
}

const ProductPage = ({ product, setShowSizeGuide, showSizeGuide }) => {
  const { showCart } = useContext(InterfaceContext)
  const [selectedProduct, setSelectedProduct] = useState()
  const { cart, addVariantToCart } = useContext(StoreContext)
  const [selectedVariant, setSelectedVariant] = useState()
  const [isSoldOut, setIsSoldOut] = useState(false)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchVariant = async () => {
      setLoading(true)
      const hasInventory = selectedProduct.variants.find(
        (el) => el.inventory_quantity > 0
      )

      if (hasInventory) {
        const variant = product.variants.find(
          (el) => el.objectId === hasInventory.id
        )

        setSelectedVariant(variant)
      } else {
        setSelectedVariant(product.variants[0])
        setIsSoldOut(true)
      }
      setLoading(false)
    }

    if (selectedProduct) {
      fetchVariant()
    }
  }, [selectedProduct])

  useEffect(() => {
    if (product && product.objectId) {
      Medusa.products.retrieve(product.objectId).then(({ data }) => {
        setSelectedProduct(data.product)
      })
    }
  }, [product])

  const handleAddToCart = () => {
    addVariantToCart({
      variantId: selectedVariant.objectId,
      quantity: 1,
      metadata: {},
    })
    showCart()
  }

  const handleSelectVariant = async (variantId) => {
    const variant = product.variants.find((el) => el.objectId === variantId)
    const { data } = await Medusa.products.variants.retrieve(variant.objectId)
    setSelectedVariant(variant)
    if (data.variant.inventory_quantity === 0) {
      setIsSoldOut(true)
    } else {
      setIsSoldOut(false)
    }
  }

  const isSale = selectedVariant && !!getVariantSalePrice(cart, selectedVariant)

  const sizeGuideTitle = (product) => {
    switch (true) {
      case product.title.toLowerCase().includes("replica"):
        return "The Replica Quilt Jacket"
      case product.title.toLowerCase().includes("original"):
        return "The Original Quilt Jacket"
      case product.title.toLowerCase().includes("waistcoat"):
        return "Quilted Waistcoat"
      default:
        return ""
    }
  }

  return (
    <Flex flexDirection="column">
      <Container>
        <ProductImage fluid={product.thumbnail?.fluid || {}} />
        {selectedProduct && selectedVariant && (
          <Description>
            <RichText>
              {!showSizeGuide && (
                <div>
                  {isSoldOut || loading ? (
                    <>
                      Sold out in this size{" "}
                      <del>{formatVariantPrice(cart, selectedVariant)} </del>
                    </>
                  ) : isSale ? (
                    <>
                      <del style={{ marginRight: "15px" }}>
                        {formatVariantPrice(cart, selectedVariant)}{" "}
                      </del>
                      {formatVariantSalePrice(cart, selectedVariant)}{" "}
                    </>
                  ) : (
                    <>{formatVariantPrice(cart, selectedVariant)} </>
                  )}
                </div>
              )}
              {product.description && !showSizeGuide
                ? documentToReactComponents(
                    product.description?.json,
                    renderOptions
                  )
                : ""}
              <Flex flexDirection="column">
                {!showSizeGuide &&
                  product.options.some((o) => o.title === "Size") &&
                  selectedProduct &&
                  selectedProduct.options.map((productOption, i) => {
                    return (
                      <StyledSelect
                        key={i}
                        value={selectedVariant.objectId}
                        onChange={(e) =>
                          handleSelectVariant(e.currentTarget.value)
                        }
                      >
                        {product.variants.map((variant, i2) => (
                          <option key={i2} value={variant.objectId}>
                            {
                              variant.options.find(
                                (op) => op.option_id === productOption.id
                              ).value
                            }
                          </option>
                        ))}
                      </StyledSelect>
                    )
                  })}
                {showSizeGuide ? null : (
                  <AddToCart
                    onClick={() => handleAddToCart()}
                    isSoldOut={isSoldOut}
                  >
                    Add to cart
                  </AddToCart>
                )}
                {(product.title.toLowerCase().includes("jacket") ||
                  product.title.toLowerCase().includes("waistcoat")) &&
                  (showSizeGuide ? (
                    <>
                      Size guide
                      <br />
                      {sizeGuideTitle(product)}
                      <br />
                      <br />
                      <SizeGuide
                        setShowSizeGuide={setShowSizeGuide}
                        type={product.sizeGuideType}
                        productTitle={product.title.toLowerCase()}
                      />
                      <br />
                      Please do not hesitate to email us at hello@lsjstudio.com
                      for size guidance or questions. We are here to assist any
                      way we can.
                    </>
                  ) : (
                    <ViewSizeGuide onClick={() => setShowSizeGuide(true)}>
                      View sizeguide
                    </ViewSizeGuide>
                  ))}
              </Flex>
            </RichText>
          </Description>
        )}
      </Container>
      {product.contentModules &&
        product.contentModules.map((cm, i) => (
          <TileSection key={cm.id} input={cm} />
        ))}
    </Flex>
  )
}

export default ProductPage
