import styled from "@emotion/styled"
import { navigate } from "gatsby"
import Img from "gatsby-image"
import React, { useEffect, useState } from "react"
import { Button, Flex, Text } from "rebass"
import useWindowDimensions from "../../../utils/use-window-dimensions"

const Container = styled(Flex)`
  width: 100%;
  padding-left: 35px;
  padding-right: 35px;
  flex-direction: column;

  ${(props) => props.theme.bp.tablet} {
    padding-right: 10px;
    padding-left: 10px;
  }

  ${(props) => props.theme.bp.mobile} {
    padding-right: 10px;
    padding-left: 10px;
  }
`

const GridColContainer = styled(Flex)`
  flex-direction: column;

  ${(props) => props.theme.bp.mobile} {
    flex-basis: 100%;
  }

  ${(props) => props.theme.bp.tablet} {
    flex-basis: 50%;
  }

  ${(props) => props.theme.bp.desktop} {
    flex-basis: 33%;
  }
`

const GridContainer = styled(Flex)`
  width: 100%;
  gap: 10px;
  justify-content: center;
`

const TileContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  margin-top: 10px;
  color: #050d2b;
  position: relative;

  #hover-title {
    opacity: 0;
  }

  #cta-title {
    opacity: 1;
  }
  cursor: pointer;

  ${(props) =>
    props.isClickable &&
    `
    &:hover {
      text-decoration: underline;
      
      #hover-title {
        opacity: 1;
        text-align: center;
      }
      
      #cta-title {
        opacity: 0;
      }
      
    }
  `}
`

const TileImage = styled(Img)`
  width: 100%;

  img {
    height: inherit !important;
  }
  &:hover {
    text-decoration: underline;
    // webkit-filter: blur(2px); /* Chrome, Safari, Opera */
    // filter: blur(2px);
  }
`

const TileCTA = styled(Button)`
  width: 100%;
  font-family: Georgia;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  color: #050d2b !important;
  background-color: #f4f0e5;
  text-transform: uppercase;
  text-align: left !important;

  padding-left: 0 !important;
  ${(props) => props.theme.bp.tablet} {
    font-size: 12px !important;
  }

  ${(props) => props.theme.bp.mobile} {
    font-size: 12px !important;
  }
`

const OnHoverTitle = styled(Text)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Georgia;
  font-weight: 600;
`

const GridTile = ({ tile }) => {
  const handleOnClick = () => {
    if (tile.linkUrl) {
      navigate(`/${tile.linkUrl}`)
    }
  }

  if (tile?.backgroundImage?.fluid) {
    return (
      <TileContainer
        isClickable={!!tile.cta || !!tile.linkUrl}
        onClick={() => handleOnClick()}
      >
        <TileImage
          fluid={tile.backgroundImage.fluid}
          isClickable={!!tile.cta || !!tile.linkUrl}
        />
        {tile.linkUrl && (
          <OnHoverTitle id="hover-title">{tile.cta || tile.title}</OnHoverTitle>
        )}
        {tile.cta && <TileCTA id="cta-title">{tile.cta}</TileCTA>}
      </TileContainer>
    )
  } else {
    return null
  }
}

const Grid = ({ input }) => {
  const { tiles } = input

  const { width } = useWindowDimensions()

  const [cols, setCols] = useState([])

  const partitionHelper = (items) => {
    const partitioned = [[], [], []]
    for (let i = 1; i <= items.length; i++) {
      if (i % 3 === 0) {
        partitioned[2].push(items[i - 1])
        continue
      }
      if (i % 2 === 0) {
        partitioned[1].push(items[i - 1])
        continue
      }
      if (i % 1 === 0) {
        partitioned[0].push(items[i - 1])
        continue
      }
    }

    return partitioned
  }

  useEffect(() => {
    const columns = partitionHelper(tiles)
    setCols(columns)
  }, [])

  return (
    <Container>
      <Text fontFamily="Georgia" py={4}>
        NEWS AND RECENT WORK
      </Text>
      {width <= 424 ? (
        tiles.map((tile, i) => <GridTile key={i} tile={tile} />)
      ) : (
        <GridContainer>
          {cols.map((col, i) => (
            <GridColContainer key={i}>
              {col.map((tile, i2) => (
                <GridTile key={i2} tile={tile} />
              ))}
            </GridColContainer>
          ))}
        </GridContainer>
      )}
    </Container>
  )
}

export default Grid
