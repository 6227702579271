import React from "react"
import _ from "lodash"
import Img from "gatsby-image"
import { Flex, Text } from "rebass"
import styled from "@emotion/styled"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Link } from "gatsby"

const Container = styled(Flex)`
  width: 100%;

  ${(props) => props.theme.bp.tablet} {
    flex-direction: row;
    gap: 10px;
    margin-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
  }

  ${(props) => props.theme.bp.mobile} {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
  }

  ${(props) => props.theme.bp.desktop} {
    flex-direction: row;
    padding-left: 35px;
    padding-right: 35px;
  }
`

const TileContainer = styled(Flex)`
  width: calc(50% - 5px);

  ${(props) => props.theme.bp.mobile} {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
`

const TileImage = styled(Img)`
  width: 100%;
  height: 100%;
`

const RichText = styled.div`
  font-family: Georgia;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 22pt;

  margin: 5%;
  margin-bottom: 0%;

  a {
    text-decoration: none;
    color: inherit;
  }

  text-align: ${(props) => (props.isCenter ? "center" : "left")};

  ${(props) => props.theme.bp.desktop} {
    font-size: 18px;
    ${(props) =>
      !props.isCenter &&
      `
      padding-left: 125px;
      padding-right: 125px;
    `}
  }

  ${(props) => props.theme.bp.tablet} {
    font-size: 16px;
    ${(props) =>
      !props.isCenter &&
      `
      padding-left: 50px;
      padding-right: 50px;
    `}
  }

  ${(props) => props.theme.bp.mobile} {
    font-size: 12px;
  }
`

const ProductDescription = styled.div`
  font-family: Georgia;
  font-style: normal;
  font-weight: normal;
  line-height: 22pt;
  color: #050d2b;

  ${(props) => props.theme.bp.mobile} {
    font-size: 14px;

    margin-left: 0%;
    margin-right: 0%;
    div {
      line-height: 16pt;
      margin-bottom: 20px;
    }
  }

  ${(props) => props.theme.bp.tablet} {
    margin-left: 0%;
    margin-right: 5%;
    font-size: 14px;
  }
  ${(props) => props.theme.bp.desktop} {
    font-size: 16px;
    margin-left: 8%;
    margin-right: 10%;

    div {
      line-height: 22pt;
      margin-bottom: 20px;
    }
  }
`

const H5Header = styled(Text)`
  ${(props) => props.theme.bp.desktop} {
    font-size: 20px !important;
  }

  ${(props) => props.theme.bp.tablet} {
    font-size: 18px;
  }

  ${(props) => props.theme.bp.mobile} {
    font-size: 14px;
  }
`

const options = {
  renderText: (text) =>
    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  renderNode: {
    [BLOCKS.HEADING_5]: (node, children) => <H5Header>{children}</H5Header>,
  },
}

const TileSection = ({ input }) => {
  const { tiles, isTextSection, centeredText } = input

  if (isTextSection) {
    const textTile = tiles[0] || null

    const hasBody = textTile.body && textTile.body.json

    return (
      <Container mb={2}>
        <RichText isCenter={centeredText}>
          {hasBody
            ? documentToReactComponents(textTile.body.json, options)
            : ""}
        </RichText>
      </Container>
    )
  }

  return (
    <Container mt={4}>
      {tiles.map((tile, i) => {
        const hasBody = tile.body && tile.body.json
        return (
          <TileContainer key={i}>
            {tile.backgroundImage ? (
              tile.linkUrl ? (
                <Link
                  to={`/${tile.linkUrl}`}
                  style={{ height: "100%", width: "100%" }}
                >
                  <TileImage fluid={tile.backgroundImage.fluid} height />
                </Link>
              ) : (
                <TileImage fluid={tile.backgroundImage.fluid} />
              )
            ) : (
              <ProductDescription>
                {hasBody ? documentToReactComponents(tile.body.json) : ""}
              </ProductDescription>
            )}
          </TileContainer>
        )
      })}
    </Container>
  )
}

export default TileSection
