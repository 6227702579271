import React from "react"
import { Flex } from "rebass"

import Hero from "./hero"
import Grid from "./grid"
import TileSection from "./tile-section"
import Tile from "./tile"
import ProductPage from "../../product/product-page"

const ContentModules = ({ allModules }) => {
  const module = (allModules || []).map((s) => {
    switch (s.internal.type) {
      case "ContentfulHero": {
        return <Hero key={s.id} input={s} />
      }
      case "ContentfulGrid": {
        return <Grid key={s.id} input={s} />
      }
      case "ContentfulTileSection": {
        return <TileSection key={s.id} input={s} />
      }
      case "ContentfulTile": {
        return <Tile key={s.id} input={s} />
      }
      case "ContentfulProduct": {
        return <ProductPage key={s.id} product={s} />
      }
      default:
        return null
    }
  })

  return (
    <Flex height="100%" flexDirection="column">
      {module}
    </Flex>
  )
}

export default ContentModules
